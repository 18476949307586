@import '~lib/styles/variables';

.AvailabilityCalendar {
  h3 {
    margin-top: $spacing-large;
    margin-bottom: $spacing-normal;
  }

  margin-bottom: $spacing-normal;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
}

.Month {
  // border: 1px solid red;
}

.monthName {
  text-align: center;
  margin-bottom: $spacing-small;
  font-weight: $font-weight-medium;
}

.MonthDays,
.MonthLabels {
  display: grid;
  // padding: 8px;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1px;
  // grid-column-gap: 1px;
  // grid-row-gap: 1px;
  // border: 1px solid $border-color-normal;
  // border-radius: 0 0 16px 16px;
}

.MonthLabels {
  // padding-top: 8px;
  // border-bottom: none;
  // border-radius: 16px 16px 0 0;
}

.label,
.Day {
  text-align: center;
  // border: 1px solid red;
}

.label {
  color: $text-color-light;
  padding: 0 0 5px 0;
  font-size: 12px;
}

.Day {
  font-size: 11px;
  padding: 2px 0 10px 0;
  height: 40px;
  color: #71767a;
  background: #f5f6f7;
  box-shadow: 0 0 0 1px #d1d6d6;
  &.isAvailable {
    // matching the color scheme on rec.gov
    background: #cfe8ff;
    color: #112f4e;
  }
  &.isLottery {
    // matching the color scheme on rec.gov
    background: #faf3d1;
    color: #332d27;
  }

  .statusLetter {
    font-size: 12px;
  }
}
