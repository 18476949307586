@import '~open-color/open-color';
@import '~lib/styles/functions';

//////////////////////////////////
// common definitions
//////////////////////////////////
$system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', /* Emojis*/ 'Segoe UI Emoji',
  /* Emojis*/ 'Segoe UI Symbol'; /* Emojis*/

$proxima-nova: 'proxima-nova', 'Proxima Nova', sans-serif;

$proxima-soft: 'proxima-soft', 'Proxima Nova', sans-serif;

// Font size values
$font-size-nano: 10px;
$font-size-small: 13px;
$font-size-normal: 14px;
$font-size-large: 18px;
$font-size-h1: $font-size-small * 3;
$font-size-h1-mobile: $font-size-small * 2.5;
$font-size-h2: $font-size-small * 2;

// Font weights (useful in conjunction with typekit)
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// Spacing values, for margins and paddings
$spacing-nano: 2px;
$spacing-tiny: 5px;
$spacing-small: 10px;
$spacing-normal: 15px;
$spacing-medium: 20px;
$spacing-large: 30px;
$spacing-huge: 60px;

// Text colors
$text-color-lighter: hsl(0, 0%, 80%);
$text-color-light: hsl(0, 0%, 60%);
$text-color-subtle: hsl(0, 0%, 36%);
$text-color-normal: hsl(0, 0%, 24%);
$text-color-dark: hsl(0, 0%, 16%);

// Line and shape colors
$border-color-light: hsl(0, 0%, 95%);
$border-color-normal: hsl(0, 0%, 90%);

// shadows
$map-shadow-normal: 1px 2px 6px 0 rgba(0, 0, 0, 0.05);

// Transitions
$transition-timing-default: 150ms ease-in-out;

// modals
$modal-width-normal: 400px;

//////////////////////////////////
// configuration values
//////////////////////////////////
$font-family-body: $system-font;
$font-family-headings: $proxima-nova;
$font-family-ui-elements: $system-font;
$navbar-height: 55px;

// see https://larsenwork.com/easing-gradients/#editor
// goes from 0 to 0.4
$bottom-scrim-ease: linear-gradient(
  to bottom,
  hsla(0, 0%, 0%, 0) 0%,
  hsla(0, 0%, 0%, 0.005) 8.1%,
  hsla(0, 0%, 0%, 0.019) 15.5%,
  hsla(0, 0%, 0%, 0.041) 22.5%,
  hsla(0, 0%, 0%, 0.068) 29%,
  hsla(0, 0%, 0%, 0.101) 35.3%,
  hsla(0, 0%, 0%, 0.137) 41.2%,
  hsla(0, 0%, 0%, 0.176) 47.1%,
  hsla(0, 0%, 0%, 0.214) 52.9%,
  hsla(0, 0%, 0%, 0.253) 58.8%,
  hsla(0, 0%, 0%, 0.289) 64.7%,
  hsla(0, 0%, 0%, 0.322) 71%,
  hsla(0, 0%, 0%, 0.349) 77.5%,
  hsla(0, 0%, 0%, 0.371) 84.5%,
  hsla(0, 0%, 0%, 0.385) 91.9%,
  hsla(0, 0%, 0%, 0.39) 100%
);

// old
// $color-primary: #6AA5E1;

// Brand colors
// $color-primary: $oc-cyan-5;
$color-primary: #08749e;
$color-accent: #ca671c;
$color-accent-secondary: $oc-blue-3;
$color-accent-tertiary: $oc-yellow-2;

// Variants
$color-primary-hover: hover-color($color-primary);
$color-primary-active: active-color($color-primary);

// Semantic colors
$color-danger: #c7341e;
$color-success: $color-accent;

// Layout variables
$tablet-max-width: 800px;
$mobile-max-width: 600px;
$navbar-collapse: $mobile-max-width;

// Stacking order
$z-index-map: 100;
$z-index-map-marker: 150;
$z-index-map-marker-selected: 160;
$z-index-sidebar: 500;
$z-index-toast: 900;
$z-index-navbar: 1000;
$z-index-popovers: 1100;
$z-index-modals: 1200;

// borders
$border-radius-normal: 5px;

// elevation
$elevation-high-box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
$elevation-high-border: 1px solid rgba(0, 0, 0, 0.04);
@mixin elevation-high {
  box-shadow: $elevation-high-box-shadow;
  border: $elevation-high-border;
}

$elevation-primary-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
$elevation-primary-border: 1px solid rgba(0, 0, 0, 0.04);
@mixin elevation-primary {
  box-shadow: $elevation-primary-box-shadow;
  border: $elevation-primary-border;
}

$elevation-secondary-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
$elevation-secondary-border: 1px solid rgba(0, 0, 0, 0.04);
@mixin elevation-secondary {
  box-shadow: $elevation-secondary-box-shadow;
  border: $elevation-secondary-border;
}

$elevation-tertiary-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
$elevation-tertiary-border: 1px solid rgba(0, 0, 0, 0.08);
@mixin elevation-tertiary {
  box-shadow: $elevation-tertiary-box-shadow;
  border: $elevation-tertiary-border;
}

//////////////////////////////////
// mixins and utils
//////////////////////////////////
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin reset-link-style {
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit;
    text-decoration: inherit;
  }
}

@mixin heading-nano {
  font-size: $font-size-nano;
  line-height: 1;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  color: $text-color-light;
  letter-spacing: 0.8px;
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-max-width) {
    @content;
  }
}
