@import '~lib/styles/variables';

.Table {
  &.block {
    width: 100%;
  }

  th,
  td {
    padding: $spacing-tiny $spacing-tiny;
    text-align: left;
  }

  thead {
    border-bottom: 1px solid $border-color-normal;
  }

  @media screen and (max-width: 520px) {
    th,
    td {
      // this isn't enough to make it fit on an iPhone, unfortunately.
      padding: $spacing-tiny $spacing-nano;
    }
  }
}
