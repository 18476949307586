.LayoutFixed {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
}
