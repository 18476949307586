@import "~lib/styles/variables";

.Popover {
  position: absolute;
  z-index: $z-index-popovers;
  margin-top: 15px;
  // filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.18));
}

.title {
  padding: 12px $spacing-normal 5px $spacing-small;
  margin: 0 0;
  max-width: 100%;
  @include heading-nano;
  @include ellipsis;
  border-bottom: 1px solid transparentize($border-color-normal, 0.3);
}

.arrow {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  clip-path: polygon(-100% -100%, 200% -100%, 200% 200%);
  background: #fff;
  height: 10px;
  width: 10px;
  // border: 1px solid red;
}

// .arrow:before {
//   // box-sizing: content-box;
//   content: "";
//   display: block;
//   height: 16px;
//   width: 16px;
//   transform: rotate(45deg);
//   border: 1px solid red;
// }
// .arrow:after {
//   // box-sizing: content-box;
//   content: "";
//   display: block;
//   height: 16px;
//   width: 16px;
//   transform: rotate(45deg);
//   border: 1px solid red;
//   // border-right: 8px solid transparent;
//   // border-left: 8px solid transparent;
//   // border-top: 8px solid #fff;
// }

.inner {
  overflow: hidden;
  border-radius: $border-radius-normal;
  background: #fff;
  position: relative;
  box-shadow: $elevation-high-box-shadow; // 1px 2px 5px rgba(0, 0, 0, 0.4);
}
