@import '~lib/styles/variables';
.Footer {
  padding: 30px 5vw;
  position: relative;
  overflow: hidden;
  border-top: 1px dotted hsl(0, 0%, 90%);
}

.Footer.dark {
  color: #FFF;
  // background: transparentize(darken(active-color($color-primary), 15%), 0.6);
  border-top: 0;
}

.inner {
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
}

.Footer a,
.Footer a:hover,
.Footer a:visited,
.Footer a:active {
  color: inherit;
  text-decoration: underline;
}

.Footer.dark a,
.Footer.dark a:visited,
.Footer.dark a:active {
  color: inherit;
}

.Footer.dark a:hover {
  color: #FFF;
}
