@import "~lib/styles/variables";

$item-padding: $spacing-tiny $spacing-normal $spacing-tiny $spacing-small;
$item-padding-mobile: $spacing-small $spacing-normal $spacing-small
  $spacing-small;
$menu-max-width: 280px;

.PopoverOuter {
  max-width: $menu-max-width;
}

ul.PopoverMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 100px;
  max-width: 100%;
  color: $text-color-subtle;
  max-height: 80vh;
  overflow-y: auto;
}

.title {
  padding: 12px $spacing-normal 5px $spacing-small;
  margin: 0 0;
  max-width: 100%;
  @include heading-nano;
  @include ellipsis;
}

ul.PopoverMenu .PopoverMenuItem {
  padding: $item-padding;
  transition: background 150ms ease;
  cursor: pointer;
  @include mobile {
    // bump up the touch target 'buffer zone'
    // on mobile
    padding: $item-padding-mobile;
  }
}

ul.PopoverMenu a.PopoverMenuItem,
ul.PopoverMenu a.PopoverMenuItem:hover,
ul.PopoverMenu a.PopoverMenuItem:visited,
ul.PopoverMenu a.PopoverMenuItem:focus,
ul.PopoverMenu a.PopoverMenuItem:active {
  color: inherit;
  text-decoration: none;
}

ul.PopoverMenu .PopoverMenuItem:hover {
  background: hover-color-light($color-primary);
}

ul.PopoverMenu .PopoverMenuItem[data-admin]:hover,
ul.PopoverMenu [data-admin] .PopoverMenuItem:hover,
ul.PopoverMenu .PopoverMenuItem[data-accent]:hover,
ul.PopoverMenu [data-accent] .PopoverMenuItem:hover {
  background: hover-color-light($color-accent);
}

ul.PopoverMenu .PopoverMenuItem[data-danger]:hover,
ul.PopoverMenu [data-danger] .PopoverMenuItem:hover {
  background: hover-color-light($color-danger);
}

.PopoverMenuItem {
  display: flex;
  align-items: center;
}

.PopoverMenuItem .icon {
  margin-right: $spacing-small;
  font-size: 1.2em;
  opacity: 0.8;
  flex-shrink: 0;
  flex-grow: 0;
}

.divider {
  background: $border-color-normal;
  opacity: 0.7;
  height: 1px;
  width: 100%;
}
