@import '~lib/styles/variables';

.SiteListPage {
  margin: 60px auto;
  // TODO bump up the 20px gutter on larger screen sizes
  // maybe match this with the grid-column-gap?
  width: calc(100% - 20px - 20px);
  max-width: 1200px;
  .Filters {
    margin-bottom: 16px;
    display: flex;
    justify-content: stretch;
  }
  .filterButton {
    margin-right: 6px;
    margin-bottom: 8px;
  }

  .keywordFilter {
    flex: 1;
    position: relative;
    margin-right: 12px;
    margin-bottom: 8px;
  }

  .keywordInput {
    width: 100%;
  }
  .clearKeyword {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 1px;
    height: 100%;
    line-height: 100%;
    width: 40px;
    color: $text-color-subtle;
    transition: color 200ms ease;
    font-size: 1.3em;

    &:hover {
      color: #424242;
    }
  }

  @media screen and (max-width: 750px) {
    .Filters {
      flex-direction: column-reverse;
    }
    .keywordFilter {
      margin-right: 0;
    }
  }
}

.filterInner {
  padding: 16px;
}
