@import '~lib/styles/variables';

.ManageUsersPage {

}

.action {
  opacity: 0.5;
}

.action:hover {
  opacity: 1;
  color: $color-primary;
}
