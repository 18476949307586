@import '~lib/styles/variables';


$initial-delay: 500ms;
$map-in-timing: 1200ms;
$map-in-duration: $map-in-timing + $initial-delay - 400ms;
$optimal-width: 340px;

.MapAnimation {
  position: relative;
  display: inline-block;

  width: $optimal-width;
  max-width: 100%;

  .map {
    opacity: 0;
    animation: map-in $map-in-timing ease $initial-delay forwards;
    width: $optimal-width;
    max-width: 100%;
  }

  .pin1 {
    position: absolute;
    // pin positioning here is wonky
    left: 22%;
    top: 23%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration - 100ms} forwards;
  }

  .pin2 {
    position: absolute;
    left: 34%;
    top: 43%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 100ms} forwards;
  }

  .pin3 {
    position: absolute;
    left: 28%;
    top: 27%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 600ms} forwards;
  }

  .pin4 {
    position: absolute;
    left: 68%;
    top: 29%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 900ms} forwards;
  }

  .pin5 {
    position: absolute;
    left: 54%;
    top: 40%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 1000ms} forwards;
  }

  .pin6 {
    position: absolute;
    left: 60%;
    top: 26%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 1200ms} forwards;
  }

  .pin7 {
    position: absolute;
    left: 90%;
    top: 44%;
    opacity: 0;
    animation: pin-in 600ms ease-in #{$map-in-duration + 1300ms} forwards;
  }
}

.outer {
  display: inline-block;
  width: $optimal-width;
  max-width: 100%;
}

.h2 {
  text-align: center;
  opacity: 0;
  animation: heading-in 800ms ease-in #{$initial-delay + 700ms} forwards;
}

.p {
  width: $optimal-width;
  max-width: 100%;
  opacity: 0;
  animation: heading-in 800ms ease-in #{$initial-delay + 900ms} forwards;
}

.cta {
  opacity: 0;
  animation: heading-in 400ms ease-in #{$map-in-duration + 1200ms} forwards;
}

@keyframes map-in {
  from {
    opacity: 0;
    transform: translate(30px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes pin-in {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0.01px);
  }
}

@keyframes heading-in {
  from {
    opacity: 0;
    // transform: translate(-50%, -20px);
  }

  to {
    opacity: 1;
    // transform: translate(-50%, 0);
  }
}
