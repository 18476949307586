@import '~lib/styles/variables';

/* TODO share some of these styles with button */
.Toast {
  position: fixed;
  z-index: $z-index-toast;
  left: 50%;
  bottom: 20px;
  height: 40px;
  border-radius: 20px;
  background: #FFF;
  display: block;
  width: auto;
  color: hsl(0, 0%, 50%);
  overflow: hidden;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 20px;

  pointer-events: none;

  opacity: 0;
  transform: translate(-50%, 2px); /* matches Marker transition offset */
  transition: opacity 400ms ease, transform 400ms ease;
  user-select: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

/* There's a rendering issue with mobile safari where the -50% offset doesn't
   recompute or something and the toast jumps to center after the animation
   completes. Probably worth looking at an alternate strategy for centering.*/
.Toast.visible {
  opacity: 1;
  transform: translate(-50%, 0.001px);
  pointer-events: auto;
}
