@import '~lib/styles/variables';

.TaskRunDetailPage {
  pre {
    background: rgb(30, 36, 49);
    color: hsl(0, 0%, 80%);
    font-size: 12px;
    overflow: auto;
    // the below styling is required to make mobile safari behave:
    white-space: pre;
    word-wrap: normal;

    word-break: normal;
  }
  :global {
    .ansi-black-fg {
      color: #fff;
    }
    .ansi-red-fg {
      color: #d77b79;
    }
    .ansi-green-fg {
      color: #c1c67a;
    }
    .ansi-yellow-fg {
      color: #f3cf86;
    }
    .ansi-blue-fg {
      color: #92b1c9;
    }
    .ansi-magenta-fg {
      color: #c0a6c7;
    }
    .ansi-cyan-fg {
      color: #9ac8c3;
    }
    .ansi-white-fg {
      color: #cfd2d0;
    }

    .ansi-bright-black-fg {
      color: #a6a8a6;
    }
    .ansi-bright-red-fg {
      color: #e5a471;
    }
    .ansi-bright-green-fg {
      color: #c1c67a;
    }
    .ansi-bright-yellow-fg {
      color: #fff;
    }
    .ansi-bright-blue-fg {
      color: #fff;
    }
    .ansi-bright-magenta-fg {
      color: #fff;
    }
    .ansi-bright-cyan-fg {
      color: #99e9f2;
    }
    .ansi-bright-white-fg {
      color: #fff;
    }

    // .ansi-black-bg {
    //   color: #fff;
    // }
    // .ansi-red-bg {
    //   color: #fff;
    // }
    // .ansi-green-bg {
    //   color: #fff;
    // }
    // .ansi-yellow-bg {
    //   color: #fff;
    // }
    // .ansi-blue-bg {
    //   color: #fff;
    // }
    // .ansi-magenta-bg {
    //   color: #fff;
    // }
    // .ansi-cyan-bg {
    //   color: #fff;
    // }
    // .ansi-white-bg {
    //   color: #fff;
    // }
  }
}
