@import '~lib/styles/variables';

.Setting {
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      flex: 1 1;
      padding-right: 16px;
    }
    @media screen and (max-width: 400px) {
      display: block;
      .label {
        padding-right: 0;
      }
      .action {
        margin-top: 8px;
      }
    }
  }
}
