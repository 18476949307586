@import '~lib/styles/variables';

.StyleguidePage {

}

.buttons {
  text-align: center;
  margin-bottom: $spacing-large;
  h3 {
    margin: 0 0;
  }
}

.btnLabel {
  font-size: $font-size-small;
  color: $text-color-light;
  text-align: right;
}

.Swatch {
  position: relative;
  display: inline-block;
  background: currentColor;
  height: 100px;
  width: 100px;
  overflow: hidden;
  margin: 0 $spacing-small $spacing-small 0;
  .label {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    padding: $spacing-nano;
    color: #FFF;
    font-size: $font-size-small;
    text-align: right;
    text-transform: uppercase;
  }
}

.Section {
  position: relative;
  margin-top: $spacing-normal;
  margin-bottom: $spacing-huge;
  .sectionTitle {
    color: $text-color-lighter;
    position: absolute;
    display: block;
    top: 0;
    left: -20px;
    top: 10px;
    transform: translate(-100%, 0);
    font-style: italic;
    font-size: $font-size-normal;
  }
}

.textLighter {
  color: $text-color-lighter;
}
.textLight {
  color: $text-color-light;
}
.textSubtle {
  color: $text-color-subtle;
}
.textNormal {
  color: $text-color-normal;
}
.textDark {
  color: $text-color-dark;
}
.borderColor {
  color: $border-color-normal;
}

.allIcons {
  columns: 3;
  line-height: 2em;
  [data-icon] {
    font-size: 1.5em;
  }
  @media screen and (max-width: 650px) {
    columns: 2;
  }
}
