@import '~lib/styles/variables';

.LandingPage {
  font-family: $proxima-nova;

  h1 {
    font-size: 50px;
    font-weight: $font-weight-medium;
    color: inherit;
    margin: 0 0;

    @include tablet {
      font-size: 36px;
      line-height: 38/36;
    }
    @include mobile {
      font-size: 28px;
      line-height: 38/36;
    }
  }

  h2 {
    font-size: 36px;
    @include mobile {
      font-size: 24px;
      line-height: 24/22;
    }
    font-weight: $font-weight-medium;
    color: inherit;
    margin: 0 0;
  }

  h3 {
    font-size: 18px;
    line-height: 22/18;
    font-weight: normal;
    color: inherit;
    margin: 0 0;

    @include mobile {
      font-size: 16px;
      line-height: 18/16;
    }
  }

  .hero {
    position: relative;
    text-align: center;
  }

  h1.hero {
    margin-top: 60px;
    color: #1A1A1A;
    @include mobile {
      margin-top: 40px;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      text-align: left;
      white-space: nowrap;
      overflow: visible;
    }
  }

  .imgContainer {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heroIllustration {
    flex: 0 0 100%;
    max-width: 1000px;
    min-width: 650px;
    width: calc(100% - 30px);
    display: inline-block;
    height: auto;
    margin-top: 30px;
    @include mobile {
      margin-top: 20px;
    }
  }

  h2.hero {
    display: block;
    font-size: 18px;
    line-height: 22/28;
    font-weight: normal;
    color: rgba(74,74,74,0.73);
    margin: 40px auto 80px auto;
    max-width: calc(100% - 30px);
    @include mobile {
      font-size: 16px;
      margin-top: 50px;
      margin-bottom: 60px;
    }
  }

  .sectionUsage {
    background: #74BFFB;
    padding: 80px 15px;
    @include mobile {
      padding: 60px 15px;
    }
    text-align: center;
    color: #FFF;
  }

  .usageExamples {
    margin: 45px auto 0 auto;
    @include mobile {
      margin-top: 30px;
    }
    display: flex;
    max-width: 855px;
    .example {
      min-width: 0;
      flex: 1;
      text-align: center;
      img {
        height: 162px;
        width: 162px;
        @include tablet {
          height: 120px;
          width: 120px;
        }
        @include mobile {
          height: 60px;
          width: 60px;
        }
      }
      h3 {
        margin-top: 20px;
      }
    }
  }

  .sectionTryIt {
    padding: 80px 15px;
    @include mobile {
      padding: 60px 15px;
    }
    text-align: center;
  }

  .tryItLinks {
    margin: 80px auto;
    @include mobile {
      margin: 40px auto 20px auto;
    }
    display: flex;
    max-width: calc(100% - 30px);
    width: 700px;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      display: inline-flex;
      width: auto;
    }

    .tryIt {
      display: inline-flex;
      align-items: center;
      text-align: left;
      @include mobile {
        margin-bottom: 30px;
      }
      & > img {
        display: block;
        margin-right: 20px;
        height: 124px;
        width: 131px;

        @include mobile {
          flex-direction: column;
          height: 71px;
          width: 75px;
        }
      }
      a {
        display: block;
        font-size: 18px;
        margin-top: 20px;
        @include mobile {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }

  .sectionSignup {
    height: 430px;
    @include mobile {
      height: 330px;
    }
    background:
      center/100% auto no-repeat url(./img/balloon_background.svg),
      #FFC04A;
    @include mobile {
      background:
      left center/200% auto no-repeat url(./img/balloon_background.svg),
      #FFC04A;
    }
    color: #FFFFFF;
    text-align: center;
    padding: 100px 15px;

    .signupCTA {
      display: inline-block;
      margin: 40px 0 0 0;
      padding: 10px 70px;
      background: #FFFFFF;
      box-shadow: 0 2px 6px 1px rgba(0,0,0,0.09);
      border-radius: 999px;
      font-size: 18px;
      color: #A17015;
      text-align: center;
      font-weight: $font-weight-medium;
      transform: scale(1.001);
      transition: transform 120ms ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
