@import '~lib/styles/variables';

.expando {
  margin-top: $spacing-normal;
  margin-bottom: $spacing-large;
  form {
    min-width: 350px;
    width: 80%;
    max-width: 100%;
    margin: 0 auto;
  }
}
