@import '~lib/styles/variables';

.RenameMapModal {

}

.form {
  margin-bottom: $spacing-normal;
  text-align: left;
}
