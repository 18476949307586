@import '~lib/styles/variables';

.ErrorPage {
  text-align: center;
  min-height: calc(100vh - #{$navbar-height});
  border: 1px solid transparent; // prevent margin collapse;

  h1 {
    margin-top: 10vh;
  }
}
