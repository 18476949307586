@import '~lib/styles/variables';

.SiteSummaryCard {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: $elevation-secondary-box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  transition: box-shadow 0.2s ease-in;
  max-width: 600px;
  &:hover {
    // @include elevation-high();
    box-shadow: $elevation-high-box-shadow;
  }
  &.noAvailableDates {
    .img {
      opacity: 0.3;
    }
  }

  a.cardLink,
  a.cardLink:visited,
  a.cardLink:active,
  a.cardLink:focus {
    color: $text-color-normal;
  }

  .cardLink:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .availability {
    font-size: $font-size-small;
    color: $text-color-light;
  }
  .title {
    font-size: $font-size-large;
    margin: 0 0;
    @include ellipsis;
  }
  .details {
    font-size: $font-size-small;
    color: $text-color-subtle;

    .detail {
      display: inline-block;
      margin-right: 16px;
    }
  }
  .img {
    color: #fff;
    // aspect-ratio: 2.39 / 1;
    height: 170px;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .watchingIcon {
    font-size: $font-size-small;
    background: #fff;
    color: #f08c00;
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 2px 12px;
    border-radius: 12px;
  }

  .inner {
    padding: 16px;
    flex: 1;
  }

  .footer {
    padding: 0 16px 16px 16px;
    display: flex;
    justify-content: space-between;
  }

  .pill {
    font-size: 11px;
    border: 1px solid;
    padding: 2px 10px;
    border-radius: 10px;
  }
}
