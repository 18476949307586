@import '~lib/styles/variables';

.EditWatchModal {
  text-align: left;

  .subhead {
    text-align: center;
    font-weight: 500;
  }

  .clearButton {
    font-size: 0.8em;
    color: $color-accent;
  }

  .daysOfWeekInput {
    display: inline-block;
    border: 1px solid $border-color-normal;
    .dayButton {
      padding: 5px 8px;
      font-weight: 500;
      font-size: 11px;
      &.isSelected {
        background-color: $color-primary;
        color: #fff;
      }
    }
  }
}
