@import '~lib/styles/variables';

.header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'actions'
    'title'
    'meta';
}

h1.title {
  grid-area: title;
  margin: $spacing-small 0;
}

.SiteMeta {
  grid-area: meta;
  font-size: $font-size-small;
  color: $text-color-subtle;
  border-bottom: 1px solid $border-color-light;

  .detail {
    display: inline-block;
    margin-right: 16px;
    &.mobileHidden {
      display: none;
    }
    @media screen and (min-width: 650px) {
      &.mobileHidden {
        display: inline-block;
      }
    }
  }
}

.SiteActions {
  grid-area: actions;
  display: flex;
  justify-content: space-between;
  margin: $spacing-small -15px;
}

.addWatch {
  margin-top: $spacing-large;
}

.availabilitySection,
.historySection {
  margin-top: $spacing-huge;
}
