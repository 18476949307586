@import '~lib/styles/variables';

.DetectionLogPage {
}

$date-width: 85px;
$date-margin: 5px;

.DetectionLogEntry {
  // border: 1px solid red;
  display: grid;
  grid-template-columns: min-content 1fr #{($date-width + $date-margin) * 2};
  grid-column-gap: $spacing-medium;
  margin-top: $spacing-large;

  @media screen and (max-width: 800px) {
    grid-template-columns: min-content 1fr #{$date-width + $date-margin};
  }

  h3 {
    margin-top: 0;
  }

  h3 a {
    color: $text-color-normal;
  }

  .badge {
    font-size: $font-size-nano;
    padding: 3px 10px;
    color: #fff;
    width: 70px;
    border-radius: 25px;
    text-align: center;
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    .badge {
      display: none;
    }
    // hack to account for the grid-column-gap
    margin-left: -$spacing-medium;
  }

  .badge.closed {
    color: $text-color-subtle;
    background: #dee2e6;
  }

  .badge.tracking {
    background: #22b8cf;
  }

  .dates {
    text-align: right;
  }

  .date {
    font-size: $font-size-nano;
    padding: 2px 10px;
    display: inline-block;
    margin-left: $date-margin;
    margin-bottom: 3px;
    background: #d8f5a2;
    font-weight: 600;
    border-radius: 3px;
    width: $date-width;
    text-align: center;
  }

  .date.claimed {
    background: #dee2e6;
    color: $text-color-subtle;
  }
}

.showMoreContainer {
  margin-top: $spacing-large;
  text-align: center;
}

.showingAll {
  color: $text-color-light;
}

.spinner {
  padding: 32px;
  text-align: center;
}
