@import '~lib/styles/variables';

.SettingsPage {

}

.expando {
  margin-top: $spacing-normal;
  margin-bottom: $spacing-large;
}
