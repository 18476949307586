@import '~lib/styles/variables';

.Navbar {
  flex: 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-normal; // TODO match this with the page gutter on small screens
  height: 55px;
  background: #FFF;
}

.outline {
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  z-index: $z-index-navbar;
}

.outline.noShadow {
  border-bottom: 1px solid hsl(0, 0%, 90%);
  box-shadow: none;
}

.outline.noBorder {
  border: none;
}

h1.logo {
  margin: 0 10px 0 5px;
  display: inline-block;
  color: #424242;
  position: relative;
  font-family: $proxima-soft;
  font-size: 21px;
  font-weight: normal;
}

h1.navbarPageTitle {
  font-size: $font-size-large;
  font-weight: $font-weight-medium;
  line-height: 1;
  margin: 0 0;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

@media screen and (max-width: $navbar-collapse) {
  .center {
    display: none;
  }
}

// .Navbar a, .Navbar a:visited {
  // text-decoration: none;
  // color: #616161;
  // transition: color 200ms ease;
// }

.logoLink {
  line-height: 0;
}

/* use pseudo-elements and letter-spacing to
   lessen the impact of changing font-weight */
a.NavLink,
a.NavLink:visited {
  position: relative;
  letter-spacing: 0.48px;
  text-decoration: none;
  color: #616161;
  transition: color 200ms ease;
}

a.NavLink.active {
  letter-spacing: 0px;
}

a.NavLink:before {
  display: inline-block;
  content: attr(data-text);
  font-weight: 600;
  letter-spacing: 0.48px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

a.NavLink span {
  display: inline-block;
  position: absolute;
  left: 0;
}

a.NavLink:hover {
  color: #424242;
}

/* TODO - scope this to just the center links and prevent them from changing
width when active */
.Navbar a.active {
  font-weight: 600;
  color: #424242;
}

.center a {
  margin: 0 10px;
}

.right button,
.right a {
  margin: 0 0 0 $spacing-small;
}

.ProfileButton {
  cursor: pointer;
  display: inline-flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  transition: opacity 150ms ease;
}

.ProfileButton span {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #FFF;
  align-items: center;
  justify-content: center;
  border: 0;
  box-shadow: none;
  font-family: 'proxima-soft', sans-serif;
  font-size: 15px;
  outline: none;
}

.ProfileButton:hover,
.ProfileButtonActive {
  opacity: 0.9;
}


.NavbarButton {
  cursor: pointer;
  display: inline-flex;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  align-items: center;
  justify-content: center;
  color: #616161;
  font-size: 18px;
  transition: all $transition-timing-default;
  @include hover {
    color: $color-primary;
    // background: hover-color-light($color-primary);
  }
  &:active,
  &.NavbarButtonActive {
    color: active-color($color-primary);
    background: hover-color-light($color-primary);
  }
}
