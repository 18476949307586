@import "~lib/styles/variables";

.LoginPage {
  min-height: calc(100vh - #{$navbar-height});
  // TODO fixme: we want this URL to be static, not dynamic
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("./backpacker.jpg") center 15% / cover no-repeat,
    linear-gradient(
      -154deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 69%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    linear-gradient(-180deg, #86a3b0 0%, #acb3ba 37%, #ba7d4a 77%, #946043 100%);
  padding: 0.1px;
}

.FloatingBox {
  margin: 10vh auto 60px auto;
  background: #fff;
  border-radius: 3px;
  width: 350px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
}

.header {
  border-bottom: 1px solid hsl(0, 0%, 90%);
  display: flex;
  justify-content: stretch;
}

.content {
  padding: 20px;
}

.content h2 {
  margin-top: 0;
}

/* TODO match bold/letter-spacing styles in Navbar here */
.headerLink,
.headerLink:visited,
.headerLink:active {
  padding: 10px;
  display: inline-block;
  flex: 1 0 auto;
  text-align: center;
  color: hsl(0, 0%, 50%);
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
}

.headerLink.active {
  color: hsl(0, 0%, 20%);
  border-bottom: 1px solid #6aa5e1;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  font-size: 15px;
  background: #6aa5e1;
  color: #fff;
  padding: 10px 30px;
  outline: none;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  transition: background 150ms ease;
}

.submitButton:hover {
  background: #5489bf;
}

.error {
  margin-top: 10px;
  text-align: center;
}
