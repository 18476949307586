@import '~lib/styles/variables';

.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1 0 auto;
  position: relative;
  &.hideFooter {
    min-height: calc(100vh - #{$navbar-height});
  }
}
