@import "~lib/styles/variables";

.Button {
  position: relative;
  font-family: $font-family-ui-elements;
  display: inline-block;
  color: hsl(0, 0%, 50%);
  padding: 9px 15px;
  border: none;
  border-radius: 999px;
  background: none;
  font-size: 13px;
  // text-transform: uppercase;
  text-decoration: none;
  transition: all $transition-timing-default, padding 300ms ease-in-out;
  outline: none;
  line-height: 1.4; // TODO - code Button and FormControl Input to match height!
}

a.Button,
a.Button:hover {
  text-decoration: none;
}

// TODO fade in/out the loading spinner!
.Button:not(.block).loading {
  padding-left: calc(12px + 1.8em);
}
.Button .spinner {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Button:disabled {
  cursor: default;
  opacity: 0.5;
}

.Button:not(:disabled) {
  &.hover {
    background: #f5f5f5;
  }

  @include hover {
    background: #f5f5f5;
  }

  &:active,
  &.active {
    background: #eeeeee;
  }
}

@mixin variant($name, $color) {
  .#{$name},
  a.#{$name},
  a.#{$name}:visited {
    color: $color;
    &:not(:disabled) {
      &.hover {
        background: hover-color-light($color);
      }

      @include hover {
        background: hover-color-light($color);
      }

      &:active,
      &.active {
        color: active-color($color);
        background: active-color-light($color);
      }
    }
  }

  .#{$name}.filled,
  a.#{$name}.filled {
    color: #fff;
    background: $color;
    &:not(:disabled) {
      &.hover {
        color: #fff;
        background: hover-color($color);
      }

      @include hover {
        color: #fff;
        background: hover-color($color);
      }

      &:active,
      &.active {
        color: #fff;
        background: active-color($color);
      }
    }
  }

  .#{$name}.subtle,
  a.#{$name}.subtle {
    color: $color;
    background: subtle-color($color);
    &:not(:disabled) {
      &.hover {
        background: subtle-color-hover($color);
      }

      @include hover {
        background: subtle-color-hover($color);
      }

      &:active,
      &.active {
        background: subtle-color-active($color);
      }
    }
  }
}

//////////////////////////////////
// color variants
//////////////////////////////////
@include variant(primary, $color-primary);
@include variant(danger, $color-danger);
@include variant(accent, $color-accent);
@include variant(default, hsl(0, 0%, 50%));

//////////////////////////////////
// display variants
//////////////////////////////////
.outline {
  border: 1px solid;
}

.block {
  display: block;
  text-align: center;
  width: 100%;
}

.small {
  padding: 2px 10px;
}
