@import '~lib/styles/variables';

.NotFoundPage {
  text-align: center;
  min-height: calc(100vh - #{$navbar-height});
  background: url('./not_found.jpg') center bottom/cover no-repeat;
  border: 1px solid transparent; // prevent margin collapse;
  font-size: $font-size-large;

  h1 {
    margin-top: 20vh;
  }
}
