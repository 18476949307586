@function subtle-color($color) {
  @return mix(hover-color($color), white, 13%);
}

@function subtle-color-hover($color) {
  @return mix(hover-color($color), white, 15%);
}

@function subtle-color-active($color) {
  @return mix(hover-color($color), white, 25%);
}

@function hover-color($color) {
  @return darken(saturate($color, 5%), 5%);
}

@function hover-color-light($color) {
  @return mix(hover-color($color), white, 5%);
}

@function active-color($color) {
  @return darken(saturate($color, 10%), 10%);
}

@function active-color-light($color) {
  @return mix(active-color($color), white, 10%);
}
