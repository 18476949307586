@import '~lib/styles/variables';

// ios hack for overscroll weirdness
@media screen and (max-width: 800px) {
  :global {
    .modalOpen #root {
      opacity: 0;
    }
  }
}

.Dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modals;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  animation: fade-in 300ms cubic-bezier(0.33, 1, 0.68, 1);
}

.Modal {
  animation: fade-in 300ms cubic-bezier(0.33, 1, 0.68, 1);
  background: #fff;
  position: relative;
  width: 800px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 0 auto;
  margin-top: $spacing-large;
  padding: $spacing-medium;
  border-radius: $spacing-small;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    border-radius: 0;
    margin-top: 0;
    padding-bottom: $spacing-huge;
  }

  .closeButton {
    font-size: 40px;
    position: absolute;
    top: $spacing-small;
    right: $spacing-small;
    color: $text-color-normal;
  }

  .title {
    text-align: center;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
