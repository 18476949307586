@import '~lib/styles/variables';

$animation-timing-wobble: cubic-bezier(0.595, 0.540, 0.315, 0.530);

.CircleSpinner {
  display: inline-block;
  position: relative;
  height: 1.5em;
  width: 1.5em;

  &.inline {
    margin-bottom: -0.38em;
  }

  svg {
    display: block;
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    stroke: currentColor;
  }
  .group {
    animation: spin 800ms infinite $animation-timing-wobble;
    transform-origin: 50% 50%;
  }
  .circle {
    opacity: 0.5;
  }
  .spin {
    $circle-length: 88;
    stroke-dasharray: $circle-length;
    stroke-dashoffset: $circle-length * 0.75;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

