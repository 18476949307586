@import '~lib/styles/variables';

.TaskRunsTable {
}
.emoji {
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', system-ui;
  font-size: 18px;
  line-height: 22.4px;
}

@media screen and (max-width: 520px) {
  table {
    min-width: 520px;
  }
  .container {
    width: 100%;
    overflow: auto;
  }
}
