@import '~lib/styles/variables';

.LoadingPage {
  .content {
    text-align: center;
    margin-top: 30%;
  }

  .spinner {
    color: $color-primary;
    font-size: $font-size-large;
  }
}
