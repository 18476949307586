@import '~lib/styles/sanitize';
@import '~lib/styles/variables';

// basics
body,
html {
  font-family: $font-family-body;
  font-size: $font-size-normal;
  color: $text-color-normal;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  font-weight: $font-weight-semibold;
  line-height: 1.15;
  color: $text-color-dark;
}

// mobile fixes
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// reset buttons

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  border: none;
  outline: none;
  padding: 0 0;
  margin: 0 0;
  background: rgba(255, 255, 255, 0);
  -moz-appearance: none;
  -webkit-appearance: none;
}

h1 {
  font-weight: bold;
  font-size: $font-size-h1;
  margin-top: $spacing-large;
  margin-bottom: $spacing-normal;
  @media screen and (max-width: 750px) {
    font-size: $font-size-h1-mobile;
  }
}

h1[data-alt] {
  font-size: $font-size-h2;
  font-weight: $font-weight-semibold;
}

h2 {
  font-size: $font-size-h2;
  margin-top: $spacing-large;
  margin-bottom: $spacing-tiny;
}

h2[data-alt] {
  font-size: $font-size-small;
  border-bottom: 1px solid $border-color-normal;
  text-transform: uppercase;
  color: $text-color-subtle;
  font-weight: $font-weight-medium;
  letter-spacing: 1px;
  padding-bottom: 2px;
}

h3 {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-nano;
}

p {
  margin-top: 0;
  margin-bottom: $spacing-normal;
}

p a,
p a:hover,
p a:active,
p a:focus,
p a:visited {
  // text-decoration: underline;
}

strong {
  // tone it down a bit
  font-weight: $font-weight-semibold;
  color: $text-color-dark;
}

// FIXME
a,
a:hover,
a:active,
a:focus,
a:visited {
  color: $color-primary;
  cursor: pointer;
  text-decoration: none;
  transition: color 150ms ease;
}

a {
  @include hover {
    color: $color-primary-hover;
  }
}

a:active {
  color: $color-primary-active;
}

// convenience attributes
[data-danger] {
  color: $color-danger;
}

[data-success] {
  color: $color-success;
}

[data-primary] {
  color: $color-primary;
}

[data-accent] {
  color: $color-accent;
}

[data-light] {
  opacity: 0.5;
}

[data-small] {
  font-size: $font-size-small;
}

[data-nano] {
  font-size: $font-size-nano;
}

[data-admin] {
  color: $color-accent;
}

[data-accent-secondary] {
  color: $color-accent-secondary;
}

[data-accent-tertiary] {
  color: $color-accent-tertiary;
}
