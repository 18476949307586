@import '~lib/styles/variables';

.PageLoadingSpinner {
  font-size: $font-size-h1;
  color: $color-primary;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
