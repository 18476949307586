@import '~lib/styles/variables';

$duration-default: 800ms;
$duration: $duration-default;
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

@mixin animation-duration($duration) {
  .svg {
    animation:
      pop
      $duration
      ease-out
      forwards;
  }
  .circle {
    animation:
      draw
      #{$duration * 0.8}
      $curve
      forwards;
  }

  .check {
    animation: draw
      #{$duration * 0.6}
      cubic-bezier(0.650, 0.000, 0.450, 1.000)
      #{$duration * 0.2}
      forwards;
  }
}

.SuccessSpinner {
  display: inline-block;
  position: relative;
  height: 1.5em;
  width: 1.5em;
  color: $color-success;

  // default timings
  @include animation-duration($duration-default);

  &.inline {
    margin-bottom: -0.38em;
  }

  &.big {
    font-size: $font-size-h2;
    @include animation-duration($duration-default * 1.5)
  }

  &.primary {
    color: $color-primary;
  }

  .svg {
    display: block;
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    stroke: currentColor;
  }

  .circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-width: 1.5;
    // this is the full length of the circle's path,
    // read using $0.getTotalLength() in devTools
    stroke-dasharray: 94;
    stroke-dashoffset: 94;
  }
  .check {
    stroke-dasharray: 22;
    stroke-dashoffset: 22;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes pop {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: scale(1.01);
  }
}
