@import '~lib/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $spacing-normal;

  h1 {
    margin-bottom: 0;
  }

  label {
    display: block;
    text-align: center;
    margin-bottom: $spacing-tiny;
    margin-right: 40px;
  }

  @media screen and (max-width: 600px) {
    label {
      margin-right: 0;
    }
  }
}
