@import '~lib/styles/variables';

.watchingNotice {
  margin-top: 20px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: $elevation-secondary-box-shadow;
  .noticeText {
    padding: 12px 8px;
    .watchingIcon {
      font-size: $font-size-small;
      background: #fff;
      color: #f08c00;
      padding: 2px 12px;
      border-radius: 12px;
      display: inline-block;
      margin: 4px 8px;
    }
  }
  .buttons {
    padding: 16px;
  }

  .noticeText {
    background: linear-gradient(to left, $oc-lime-7, $oc-lime-9);
  }
}
